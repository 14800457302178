import * as s from "./styles/globalStyles";
import {StyledImg} from "./components";
import { NavBar } from "./NavBar";
import React  from 'react';
import {Helmet} from "react-helmet";

function About() {  
return (
<s.Screen>
<s.ScrollBar>
<video id="background-video" preload="auto" autoPlay muted loop poster="config/images/stars.webp">
<source src={require('./videos/frontpage.webm')} type="video/webm" />
</video>
      <s.Container
        flex={1}
        ai={"center"}
        style={{padding: 0, width:"100vw", height: "100%", margin: 0,overflowX: "hidden",overflowY:"scroll",backgroundColor:"rgba(0, 0, 0, 0.3)"}}
        >
      <NavBar/>
      <s.ContainerCollectable style={{padding:"10px", alignItems:"center"}}>
      <s.TextTitle style={{marginTop:50, marginBottom:20}}>Our Story</s.TextTitle>
      <s.TextDescription style={{ marginTop:"15px",color:"white", width:"100%", padding:0, fontWeight:900, marginBottom:"15px"}}> 
        This is a two member team doing comics on nights and weekends.
        We are seasoned Web3 enjoyers and in it for the love of the game.
        </s.TextDescription>

      <StyledImg className="clickable"  id="dev" src={"/config/images/dev.webp"} alt= "thisisourdev" loading='eager'
            style={{cursor:"pointer", pointerEvents:"auto",marginTop:"20px",marginBottom:"20px",alingSelf:"center",width:"100%", height:"auto",boxShadow:"0px 0px 9px 3px #0ff",
            border: "1px solid black"}} />
<div id="simpleModal3" className="modal3">
        <div className="modal-content3">
            <span className="closeBtn3">&times;</span>
            <s.TextSubTitle
                                  style={{
                                    marginTop:0,
                                    width: "95%"
                                  }}
      > pyromon
      </s.TextSubTitle>
            <s.TextDescriptionSmall
                                  style={{
                                    padding:5,
                                    marginTop:10,
                                    width: "90%"
                                  }}
            >
              this is our dev and script writer dawg. your investments are in great hands!
            </s.TextDescriptionSmall>

        </div>
    </div>
    <Helmet async >
    <script async type="text/javascript" src="dev.js"></script>
    </Helmet>
        <StyledImg className="clickable" id="artist" src={"/config/images/artist.webp"} alt= "thisisourartist" loading='eager'
            style={{cursor:"pointer", pointerEvents:"auto",marginTop:"20px",marginBottom:"20px",alingSelf:"center",width:"100%", height:"auto",boxShadow:"0px 0px 9px 3px #0ff",
            border: "1px solid black"}} />
       <div id="simpleModal4" className="modal4">
        <div className="modal-content4">
            <span className="closeBtn4">&times;</span>
            <s.TextSubTitle
                                  style={{
                                    marginTop:0,
                                    width: "95%"
                                  }}
      > threat kreator
      </s.TextSubTitle>
            <s.TextDescriptionSmall
                                  style={{
                                    padding:5,
                                    marginTop:10,
                                    width: "90%"
                                  }}
            >
            the design vision for pall-o is that it will be just bunch of balls hanging around. 
            </s.TextDescriptionSmall>

        </div>
    </div>
    <Helmet async >
    <script async type="text/javascript" src="artist.js"></script>
    </Helmet>
      </s.ContainerCollectable>
                  <s.TextDescriptionSmall
                  style={{textAlign:"center", fontSize:13, marginBottom:10, width:"100%"}}>
                  © 2024 PALL-O. || Powered by Solana Blockchain
                  </s.TextDescriptionSmall>
                  </s.Container>
                </s.ScrollBar>
            </s.Screen>
  );
}
export default About;