import styled from "styled-components";
export const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--button);
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  :hover{
    opacity: 0.7;
  }
`;

export const StyledButton2 = styled.button`
  padding: 15px;
  border:  20;
  border-image: linear-gradient(to right, darkblue, darkorchid) 3;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  font-family: inherit;
  width: 100%;
  width: 170px;
  cursor: pointer;
  position: center;
  color: "white";
  :hover{
    opacity: 0.5;
  }
`;

export const StyledButton3 = styled.button`
pointer-events: auto;
cursor: pointer;
text-decoration: none!important;
background-image: linear-gradient(to right, #ff00cc 0%, #333399  51%, #ff00cc  100%);
    padding: 15px 40px;
    font-size:23px;
    align-self: center;
    font-weight: 900;
    font-family: inherit;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 190% auto;
    color: white;            
    box-shadow: 0 0 10px #ffc0eb;
    border-radius: 10px;
    display: block;

`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--primary);
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 70%;
  @media (min-width: 700px) {
    flex-direction: row;
  }
  @media (max-width: 1200px) {
    width: 95%;
    }
`;

export const ResponsiveWrapperImg = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
  @media (min-width: 700px) {
    flex-direction: row;
  }
  @media (max-width: 700px) {
    width: 95%;
    }
`;

export const StyledImg = styled.img`
background-color: var(--accent);
padding: 0px;
pointer-events: none;
transition: width 0.5s;
-webkit-touch-callout: none; /* iOS Safari */
-webkit-user-select: none; /* Safari */
 -khtml-user-select: none; /* Konqueror HTML */
   -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none;
`;

export const StyledLink = styled.a`
  color: var(--primary-text);
  text-decoration: none;
`;