import React from "react";
import {Link} from 'react-router-dom';
import "./styles/navbar.css";
import * as s from "./styles/globalStyles";
import { StyledImg} from "./components";

export const NavBar = () => {
    return(
<s.Container
        flex={1}
        ai={"center"}
        jc={"center"}
        className="NavBar"
        style={{width:"100%",minHeight:"458", backgroundImage:"url(/config/images/Banner3.webp)"}}
      >
         <Link to="/home" aria-label="main" style={{ textDecoration:"none"}}>
         <StyledImg
          alt={"logo"}
          src={"/config/images/logo.webp"}
          style={{objectFit:"contain",padding:10,maxWidth:"100%",  width: "500px", height:"auto",cursor:"pointer", pointerEvents:"auto",alignSelf:"center",backgroundColor: "transparent", marginTop:15, marginBottom:25}}
       ></StyledImg>
      </Link>     
      <div className="bg">
<div className="dropdown">
     <button className="button"> main menu </button>
     <div className="content">
      <a href="/home" aria-label="main">HOME</a>
      <a href="/work" aria-label="main">WORK</a>
      <a href="/about" aria-label="main">ABOUT</a>
      <a href="https://pall-o.notion.site/Pall-O-Trash-Bin-4d3065c3842b43798c057f5a86ff833a" target="_blank" aria-label="Blog">BLOG</a>
     </div>
     </div>     
     </div>  
 
        </s.Container>
    );
}

