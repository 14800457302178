import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import Main from "./Front";
import Collectables from "./Collectables";
import About from "./About";
import reportWebVitals from "./reportWebVitals";
import "./styles/reset.css";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <Router>
      <Routes>
    <Route exact path='/' element={<App/>} />
    <Route exact path='https://pall-o.notion.site/Pall-O-Trash-Bin-4d3065c3842b43798c057f5a86ff833a'/>
    <Route exact path='/home'element={<Main/>} />
    <Route exact path='/work'element={<Collectables/>} />
    <Route exact path='/about'element={<About/>} />
    </Routes>
    </Router>
);

reportWebVitals();
