import styled from "styled-components";

// Used for wrapping a page component
export const Screen = styled.div`
  background-color: rgba(0,0,0,0);
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100vh;
  min-width:100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

// Used for providing space between components
export const SpacerXSmall = styled.div`
  height: 8px;
  width: 8px;
`;

// Used for providing space between components
export const SpacerSmall = styled.div`
  height: 16px;
  width: 16px;
`;

// Used for providing space between components
export const SpacerMedium = styled.div`
  height: 24px;
  width: 24px;
`;

// Used for providing space between components
export const SpacerLarge = styled.div`
  height: 32px;
  width: 32px;
`;

// Used for providing a wrapper around a component
export const Container = styled.div`
  display: flex;
  width: 100%;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  background-color: ${({ test }) => (test ? "pink" : "none")};
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
  @media (max-width: 700px) {
    width: 95%;
    }
`;

export const ContainerMint = styled.div`
  display: flex;
  width: 60%;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: center;
  align-items: center;
  @media (max-width: 700px) {
    width: 100%;
    }
`;

export const ContainerCollectable = styled.div`
  display: flex;
  width: 45%;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: center;
  align-items: center;
  @media (max-width: 700px) {
    width: 95%;
    }
`;

export const ScrollBar = styled.div`
  overflow-y: auto;
  height: 100%;
  position: absolute;

`;

export const  TextTitle = styled.p`
text-transform:none;
  color: var(--primary-text);
  width:90%;
  text-align: center;
  margin-top: 0px;
  font-size: 36px;

  font-weight: 900;
  line-height: 1.2;
  animation: "flicker 4s infinite";
  background: rgba(0,0,0,0);
  text-shadow:
 0 0 7px #f071c7, 0 0 10px #ffc0eb, 0 0 11px #ffc0eb, 0 0 42px #de12b9,0 0 42px #de12b9,0 0 10px #a412de,0 0 10px #a412de,0 0 30px #c636ff;
  @keyframes flicker {
    0%, 19.999%, 22%, 62.999%, 64%, 64.999%, 70%, 100% {
      //text-shadow: none;
      opacity: .99;
      
    }
    20%, 21.999%, 63%, 63.999%, 65%, 69.999% {
      opacity: 0.4;
    }
  }


  @media (max-width: 700px) {
    font-size: 24px;
  }

`;


export const TextSubTitle = styled.p`
font-size: 26px;
font-weight: 900;
text-align: flex-start;
color: var(--primary-text);
line-height: 1.6;
text-transform: uppercase;
background: rgba(0,0,0,0);
border: none;
padding:20px;
align-self:relative;
width: 100%;
  text-shadow:
  0 0 7px #f071c7, 0 0 10px #ffc0eb, 0 0 11px #ffc0eb, 0 0 42px #de12b9,0 0 42px #de12b9,0 0 10px #a412de,0 0 10px #a412de,0 0 30px #c636ff;
  @media (max-width: 700px) {
    padding: 9px;
    font-size: 24px;
    width: 100%;
  }
  `;

export const TextDescription = styled.p`
text-transform: uppercase;
width:90%;
padding:20px;
margin-top: 0px;
margin-bottom:10px;
color: var(--primary-text);
text-align: relative;
align-self: flex-start;
font-size: 18.5px;
line-height: 1.9;

  @media (max-width: 700px) {
    padding: 9px;
    font-size: 14.5px;
    width: 96%;
    line-height: 1.5;
  }

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none;
`;

export const TextDescriptionSmall = styled.p`
text-transform: uppercase;
padding:10;
margin-top:20px;
margin-bottom:20px;
color: var(--primary-text);
text-align: justify;
align-self: center;
font-size: 19px;
line-height: 1.7;
width: 55%;
  @media (max-width: 700px) {
    font-size: 15px;
    width: 80%;
    line-height: 1.5;
  }

`;
export const StyledClickable = styled.div`
  :active {
    opacity: 0.6;
  }
`;

export const Radio = styled.div`
  display: flex;
  width: 40%;
  @media (max-width: 500px) {
    width: 85%;
  }
  padding: 30px;
  @media (max-width: 800px) {
    padding: 10px;
  }
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  background-color: ${({ test }) => (test ? "pink" : "none")};
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
`;

export const ContainerManifesto = styled.div`
  display: flex;
  width: 55%;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: center;
  align-items: center;
  @media (max-width: 700px) {
    width: 95%;
    }
`;