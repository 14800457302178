import {Link} from 'react-router-dom';
import * as s from "./styles/globalStyles";
import {StyledImg} from "./components";
import React  from 'react';

function App() {  
return (
<s.Screen>
<s.ScrollBar>
<video id="background-video" preload="auto" autoPlay muted loop poster="config/images/stars.webp">
<source src={require('./videos/frontpage.webm')} type="video/webm" />
</video>
      <s.Container
        flex={1}
        ai={"center"}
        style={{padding: 0, width:"100vw", height: "100%", overflowX: "hidden",overflowY:"scroll",backgroundColor:"rgba(0, 0, 0, 0.3)",
      justifyContent:"center", alignContent:"center", alignItems:"center"}}
        >
          <StyledImg
          className = "CP"
          alt={"logo"}
          src={"/config/images/plop.gif"}
          style={{padding:10,marginLeft:"-30px", marginBottom:30,
          backgroundColor: "transparent",  width: "160px", pointerEvents: "none"}}
       ></StyledImg> 
         <StyledImg
          alt={"logo"}
          src={"/config/images/logo.webp"}
          style={{ padding:10, marginBottom:5, top:"50%", bottom:"50%",
          pointerEvents: "none",backgroundColor: "transparent",  width: "400px", height:"auto", alignSelf:"center"}}
       ></StyledImg> 
      <Link to="/home" aria-label="home" style={{ textDecoration:"none"}}>
          <s.TextSubTitle className= "blink_me" style={{padding:10,marginTop:"0px",position:"center",textAlign:"center",textShadow:"none"}}>
            ENTER
          </s.TextSubTitle>
          </Link> 
 </s.Container>
                </s.ScrollBar>
            </s.Screen>
  );
}
export default App;