import {Link} from 'react-router-dom';
import * as s from "./styles/globalStyles";
import {ResponsiveWrapper,StyledButton3, StyledImg} from "./components";
import { NavBar } from "./NavBar";
import React  from 'react';
import {Helmet} from "react-helmet";

function Front() {  
return (
<s.Screen>
<s.ScrollBar>
<video id="background-video" preload="auto" autoPlay muted loop poster="config/images/stars.webp">
<source src={require('./videos/frontpage.webm')} type="video/webm" />
</video>
      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 0, width:"100vw", height: "100%", margin: 0,overflowX: "hidden",overflowY:"scroll",backgroundColor:"rgba(0, 0, 0, 0)"}}
        >
            <NavBar/>
          <s.ContainerMint flex={1} jc={"center"} ai={"center"}
          style={{
          padding:0, margin:0, marginTop:10 }}>
          <iframe  width="100%" height="300px" src="https://www.youtube.com/embed/6tRs48gKywc?si=NAAboK1geopsfCQd"
          title="YouTube video player" 
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen>            
          </iframe>
</s.ContainerMint>
          <ResponsiveWrapper
          style={{
            marginTop:0,
            alignItems:"center" }}>
        <s.ContainerMint
        flex={1}
        ai={"center"}
        jc={"justify"}
        style={{
          padding: 0,
          alignContent:"center",
          alignItems: "center" }}>
            <s.TextSubTitle
            style={{color:"white", marginTop:10}}>
              WHAT IS PALL-O?
            </s.TextSubTitle>
            <s.TextDescription
            style={{marginTop:0, marginBottom:0}}>
              What happens when you bring together the craziest script writers and the most banned cult artist you can find? You get Pall-O comics!
            </s.TextDescription> 
          <s.TextDescription
          style={{marginTop:0, marginBottom:5}}>
            PALL-O is a collactible on-chain comic series published on Solana blockchain.
            </s.TextDescription> 
            </s.ContainerMint>
            <div className="computer" >
            <StyledImg
            id="computer2"
            loading="lazy"
            className="clickable"
              alt="notepad"
              src={"/config/images/PC512.webp"}
              style={{alignSelf:"relative",backgroundColor: "transparent",cursor:"pointer", pointerEvents:"auto", marginTop:10, marginBottom: 10,padding: 20, width: "370px", transform: "rotate(.5deg)"}}
            />
       <button id="computerbutton" aria-label="Turn power on" className="screenBtn"></button>
       </div>
            </ResponsiveWrapper>
            <div id="simpleModal" className="modal">
        <div className="modal-content">
            <span className="closeBtn">&times;</span>
            <s.TextSubTitle
                                  style={{
                                    marginTop:0,
                                    width: "95%"
                                  }}
      > THE KREATION
      </s.TextSubTitle>
            <s.TextDescriptionSmall
                                  style={{
                                    padding:5,
                                    marginTop:10,
                                    width: "90%"
                                  }}
            >
            Futuristic, but at the same time nostalgic. The experimental comic art is hand drawn. The style 
            draws inspiration from retro video game graphics.
            It implements hints of Nordic culture featuring roughly beautiful landscapes, sauna scenes, and dark jokes.
            This creates an exotic sci-fi world never seen before in comics.
            </s.TextDescriptionSmall>
            <StyledImg
              alt="hoodlovespallo"
              src={"/config/images/hoodloveit.webp"}
              style={{maxWidth: "100%",backgroundColor: "transparent",pointerEvents:"none", display:"block",alingSelf:"center",marginLeft:"auto",marginRight:"50%", padding: 10, width: "500px"}}
            />
            <s.TextSubTitle
                                  style={{
                                    marginTop:0,
                                    width: "95%"
                                  }}
      > THE HUMAN EXPERIMENT
      </s.TextSubTitle>
          <s.TextDescriptionSmall
                      style={{
                        width: "90%",
                        padding:5,
                        marginTop:15,
                      }}
            >
            To boost the creative process we kept the artist locked in a tiny room called the art cave,
            made her listen to 90's vaporwave day in day out to crush all
            creativity blocking barriers and to come up with new original ideas.
            </s.TextDescriptionSmall>
            <s.TextDescriptionSmall
                      style={{
                        width: "90%",
                        padding:5,
                        marginTop:15,
                      }}
            >
            The artist behind PALL-O didn't see day light for months to end, nor was allowed to consume anything else but
            own thoughts. By not showing the artist what trends were currenlty popular amongst 
            creators, we were able to create a master piece that could be argued to be close to the 
            peak of human creativity.

            </s.TextDescriptionSmall>
        </div>
    </div>
    <Helmet async >
    <script async type="text/javascript" src="scripts.js"></script>
    </Helmet>

    <s.Container
                    style={{
                      position: "relative",
                      alignItems: "center",
                      width:"100%",
                      marginBottom:"20px",
                      marginTop: "20px"
                    }}>
            <StyledButton3 style={{ cursor: "auto", position:"absolute"}}>READ THE COMICS!</StyledButton3>

            <img id="image" src="webcam.jpg"/>

            </s.Container>
            <s.Radio 
        style={{
          position: "flex",
          boxShadow:"0px 0px 20px 0px #0ff",
          border: "2px solid black", borderRadius: "25px",
          marginTop:"4px",
          marginBottom:"10px",
          alignItems: "center",
        }}
        image={"/config/images/SeriesBanner.webp"} alt= "radio background" loading="lazy"
      >
        
        <s.ContainerMint style={{marginTop: "50px",padding:20, width:"100%", alignContent:"center", alignItems:"center"}}>
            <a href="https://dreader.app/comic/pall-o" target="_blank" style ={{textDecoration:"none"}}>
            <StyledButton3 style={{width:"auto",maxWidth:"100%",opacity:"100%",position: "center",
            marginTop:"20px", marginBottom:"20px", alignSelf:"center", textAlign:"center", lineHeight:"10px",
            verticalAlign:"middle" 
          }}
            >
              <StyledImg
              alt="dreader"
              src={"/config/images/dreader.png"}
              style={{padding:"5px",verticalAlign:"middle" ,position:"justify",maxWidth: "100%",
                backgroundColor: "transparent",pointerEvents:"none", display:"inline-block",width: "60px"}}
            />
              ISSUE 1</StyledButton3>
            </a>
      </s.ContainerMint>
      </s.Radio>
            <s.ContainerMint
        flex={1}
        ai={"center"}
        jc={"justify"}
        style={{
          padding: 0,
          marginTop:"20px",
          alignContent:"center",
          alignItems: "center"}}>
            <StyledImg
            className="pallo"
            id="art"
            loading="lazy"
              alt="plop room"
              src={"/config/images/ploproomlogo.webp"}
              style={{maxWidth: "100%", transition: "all .2s ease-in-out",cursor:"pointer",alignSelf:"center", pointerEvents:"auto",alignSelf:"relative",backgroundColor: "transparent", marginTop:10, marginBottom: 10,padding: 10, width: "auto"}}
            />
            <div id="simpleModal2" className="modal2">
        <div className="modal-content2">
            <span  className="closeBtn2">&times;</span>
            <s.TextSubTitle
                                  style={{
                                    width: "95%"
                                  }}
      > THE STORY
      </s.TextSubTitle>
          <s.TextDescriptionSmall
                      style={{
                        padding:5,
                        marginTop:10,
                        width: "90%"
                      }}
            >
              Pall-O is a dark-humored sci-fi story about a parallel universe inside computers.
              This world is run by Elites who want to make the network as profitable as possible.
              Elites release programs that destroy useless inhabitants within the network.
              The people need to find a way to stay relevant or face permanent termination.
              However, one person has had enough and decides to stop the madness - with a duck float.
              </s.TextDescriptionSmall>
              <StyledImg  src={"/config/images/plop.gif"} alt= "plop" loading='eager'
            style={{display:"block",alingSelf:"center",marginLeft:"auto",marginRight:"60%",   width: "180px" ,background:"none", padding:10}} />
   
            <s.TextDescriptionSmall
                      style={{
                        padding:5,
                        marginTop:10,
                        width: "90%"
                      }}
            >
            The creators of Pall-O are two Finnish millennials, trapped in economic chaos and constantly feeling like outsiders in life.
            This is not your average fictional adventure; it mirrors the challenges of real life.
            </s.TextDescriptionSmall>       
        </div>
    </div>
    </s.ContainerMint>
    <Helmet async >
    <script async type="text/javascript" src="art.js"></script>
    </Helmet>
    
        <s.ContainerMint
        flex={1}
        ai={"center"}
        jc={"center"}
        style={{
          marginTop:"10px",
        }}
      >
          <s.TextTitle
      style={{
        marginTop:"20px",
        textAlign: "center",
        color: "var(--accent-text)",
        fontWeight: 900,
      }} 
      > TEAM
      </s.TextTitle>
      <s.SpacerSmall/>
      <ResponsiveWrapper flex={1} style={{padding:0}}>
        <s.ContainerMint flex={1}  ai={"center"}  style={{padding: 10, alignItems:"center", alignContent:"center", marginTop:"0", marginBottom:"auto"}}>
            <StyledImg  src={"/config/images/pyromon.webp"} alt= "Pyromon" loading='lazy'
            style={{alingSelf:"center",width:"200px", height:"auto", padding:"0px",boxShadow:"0px 0px 9px 0px #0ff",
            border: "1px solid black"}} />
    
            <s.TextDescriptionSmall
              style={{
                textAlign: "center",
                marginLeft: 0,
                padding:0 ,
                color: "var(--accent-text)",
                textShadow:"none",
                width:"100%"
              }}
            >
              PYROMON
              </s.TextDescriptionSmall> 
              <s.TextDescriptionSmall style={{padding:"0px", textAlign:"center", width:"100%"}}>
                DEV, WRITER, and DESIGNER.
              </s.TextDescriptionSmall>
              </s.ContainerMint> 
              <s.SpacerSmall/>         
          <s.ContainerMint flex={1}  ai={"center"}
          style={{padding: 10, alignItems:"center", alignContent:"center", marginTop:"0", marginBottom:"auto"}}>
              <Link to="https://threatkreator.com/" target="_blank" aria-label="TK art page" style={{ textDecoration:"none"}}>
            <StyledImg  alt= "Threat Kreator" loading='lazy' jc={"center"} ai={"center"} src={"/config/images/tk.webp"}
            style={{width:"200px", height:"auto", padding:"0px",boxShadow:"0px 0px 9px 0px #0ff",
            border: "1px solid black"}} />
            </Link>    
            <s.TextDescriptionSmall
              style={{
                textAlign: "center",
                marginLeft: 0,
                padding: 0,
                color: "var(--accent-text)",
                textShadow:"none",
                width:"100%"
                
              }}
            >
              THREAT KREATOR
              </s.TextDescriptionSmall> 
              <s.TextDescriptionSmall style={{ width:"100%", textAlign:"center"}}>
              ARTIST, WRITER AND ANIMATOR
              </s.TextDescriptionSmall>
            </s.ContainerMint>
                  </ResponsiveWrapper>
                  </s.ContainerMint>
                  <s.TextDescriptionSmall
                  style={{textAlign:"center", fontSize:13, marginBottom:10, width:"100%"}}>
                  © 2024 PALL-O || Powered by Solana Blockchain
                  </s.TextDescriptionSmall>
                  </s.Container>
                </s.ScrollBar>
            </s.Screen>
  );
}
export default Front;