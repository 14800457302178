import * as s from "./styles/globalStyles";
import {StyledImg,ResponsiveWrapper,StyledButton3} from "./components";
import { NavBar } from "./NavBar";
import React  from 'react';
import {Link} from 'react-router-dom';

function Collectables() {  
return (
<s.Screen>
<s.ScrollBar>
<video id="background-video" preload="auto" autoPlay muted loop poster="config/images/stars.webp">
<source src={require('./videos/frontpage.webm')} type="video/webm" />
</video>
      <s.Container
        flex={1}
        ai={"center"}
        style={{padding: 0, width:"100vw", height: "100%", margin: 0,overflowX: "hidden",overflowY:"scroll",backgroundColor:"rgba(0, 0, 0, 0.3)"}}
        >
      <NavBar/>
      <s.ContainerCollectable style={{padding:"10px", alignItems:"center"}}>
      <s.TextTitle style={{marginTop:50, marginBottom:20}}>Comic series</s.TextTitle>
      <s.TextDescription style={{ color:"white", objectFit:"contain", width:"100%",  padding:10}}>
      Pall-O comics are an ongoing digital comic series published exclusively on dReader app. First issue is out and free to read for the app users.
          </s.TextDescription>
          <ResponsiveWrapper
          style={{alignItems:"center"}}>
      <s.Container
        flex={1}
        ai={"center"}
        style={{ width:"300px", height: "auto", marginTop:"20px",marginBottom:"20px", margin:15}}
        >
            <StyledImg  src={"/config/images/EpisodeCover.webp"} alt= "activationcover" loading='lazy'
            style={{width:"inherit", height:"inherit",boxShadow:"0px 0px 9px 3px #0ff", border: "1px solid black"}}/>
            </s.Container>
      <s.Container
        flex={1}
        ai={"center"}
        style={{width:"300px", height: "auto", marginTop:"20px",marginBottom:"20px", margin:15}}
        >
            <StyledImg  src={"/config/images/Activation.webp"} alt= "activationcover" loading='lazy'
            style={{width:"inherit", height:"inherit",boxShadow:"0px 0px 9px 3px #0ff", border: "1px solid black"}}/>
            </s.Container>
            </ResponsiveWrapper>
        
            <s.TextDescription style={{ color:"white", width:"100%",marginTop:10, padding:0, fontWeight:900}}> 
        Activation
        </s.TextDescription>
          <s.TextDescription style={{ color:"white", width:"100%", padding:0}}>
           First ever Pall-O comic issue and the first ever comic with Threat Kreator visuals.
          </s.TextDescription>
          </s.ContainerCollectable>
          <s.ContainerMint style={{marginTop: "20px",padding:20, width:"100%", alignContent:"center", alignItems:"center"}}>
            <a href="https://dreader.app/comic/pall-o" target="_blank" style ={{textDecoration:"none"}}>
            <StyledButton3 style={{width:"auto",maxWidth:"100%",opacity:"100%",position: "center",
            marginBottom:"20px", alignSelf:"center", textAlign:"center", lineHeight:"10px",
            verticalAlign:"middle" 
          }}
            >
              <StyledImg
              alt="dreader"
              src={"/config/images/dreader.png"}
              style={{padding:"5px",verticalAlign:"middle" ,position:"justify",maxWidth: "100%",
                backgroundColor: "transparent",pointerEvents:"none", display:"inline-block",width: "60px"}}
            />
              ISSUE 1</StyledButton3>
            </a>
      </s.ContainerMint>
                  <s.TextDescriptionSmall
                  style={{textAlign:"center", fontSize:13, marginBottom:10, width:"100%"}}>
                  © 2024 PALL-O. || Powered by Solana Blockchain
                  </s.TextDescriptionSmall>
                  </s.Container>
                </s.ScrollBar>
            </s.Screen>
  );
}
export default Collectables;